import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`While writing code for DynamoDB, I started by creating a class to fake how DynamoDB could work, there were a lot of flaws in the logic, but it worked - it was mostly just putting dictionaries into a global dict.`}</p>
    <p>{`This worked okay when all I had to do was put/get items, but when it came time to use other things, such as `}<inlineCode parentName="p">{`query`}</inlineCode>{` with different filters, variable names and `}<inlineCode parentName="p">{`begins_with`}</inlineCode>{` statements, it became hard to use the fake class.`}</p>
    <p>{`Luckily, AWS created `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/DynamoDBLocal.DownloadingAndRunning.html"
      }}>{`DynamoDB Local`}</a>{`, which allows you to setup a local DynamoDB that works. You can use all the commands available in your SDK and test your code as if using your  AWS account.`}</p>
    <h2 {...{
      "id": "using-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-docker",
        "aria-label": "using docker permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using Docker`}</h2>
    <p>{`DynamoDB Local requires you to have the Java Runtime Environment. If you use Java and have JRE installed, you can follow the installation details in the `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/DynamoDBLocal.DownloadingAndRunning.html"
      }}>{`DynamoDB Local documentation`}</a>{`.`}</p>
    <p>{`I didn't want to install the Java Runtime Environment on my computer, so Docker was the way to go. This example will use the most straightforward recipe to get DynamoDB Local installed.`}</p>
    <p>{`Let's start by creating a `}<inlineCode parentName="p">{`docker-compose.yml`}</inlineCode>{` file and adding all the required things:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`version: "3.8"
services:
  dynamodb:
    image: "amazon/dynamodb-local:latest"
    container_name: dynamodb
    ports:
      - "8000:8000"
    volumes:
      - "./docker/dynamodb:/home/dynamodblocal/data"
    working_dir: /home/dynamodblocal
    command: "-jar DynamoDBLocal.jar -sharedDb -dbPath ./data"
    environment:
      - AWS_ACCESS_KEY_ID=""
      - AWS_SECRET_ACCESS_KEY=""
      - AWS_SESSION_TOKEN=""
      - AWS_DEFAULT_REGION=us-east-1
`}</code></pre>
    <p>{`Note that we are passing some environment variables, they need to be set, but you don't need your real AWS credentials.`}</p>
    <p>{`Now, all we need to do is run the `}<inlineCode parentName="p">{`docker-compose up`}</inlineCode>{` to pull the image and run DynamoDB Local. DynamoDB will be available at `}<inlineCode parentName="p">{`http://localhost:8000`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "using-dynamodb-local",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-dynamodb-local",
        "aria-label": "using dynamodb local permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using DynamoDB Local`}</h3>
    <p>{`Now that we have the database running locally, we need to do one more thing - use it instead of the AWS service. When creating our client or resource, we need to pass `}<inlineCode parentName="p">{`endpoint_url="http://localhost:8000"`}</inlineCode>{`  In this article, I'll use the Python library  `}<inlineCode parentName="p">{`aioboto3`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import aioboto

async def put_item(item: dict):
    """Put item in the local dynamodb."""
    session = aioboto.Session()
    async with session.resource("dynamodb", "us-east-1", endpoint_url="http://localhost:8000") as dynamodb:
    table = await dynamodb.Table("Books")
    
    await table.put(Item=item)
    
`}</code></pre>
    <p>{`If you run this command, you will see that it will fail because the table doesn't exist in your DynamoDB Local. You will have to create a new table before using your local database.`}</p>
    <h2 {...{
      "id": "creating-a-table",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#creating-a-table",
        "aria-label": "creating a table permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating a table`}</h2>
    <p>{`Let's copy the code from the example in the `}<a parentName="p" {...{
        "href": ""
      }}>{`How to create and use indexes in DynamoDB`}</a>{` article. We can create a new table with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`async def create_table_and_index():
    """Create DynamoDB table with index."""
    session = aioboto3.Session()
    async with session.resource("dynamodb") as dynamodb:
        table = await dynamodb.create_table(
            TableName="Books",
            KeySchema=[
                {"AttributeName": "BookTitle", "KeyType": "HASH"},  # Partition key
                {"AttributeName": "Publisher", "KeyType": "RANGE"},  # Sort key
            ],
            AttributeDefinitions=[
                #
                # Table attributes.
                #
                {"AttributeName": "BookTitle", "AttributeType": "S"},
                {"AttributeName": "Publisher", "AttributeType": "S"},
                #
                # Index attributes.
                #
                {"AttributeName": "Author", "AttributeType": "S"},
            ],
            GlobalSecondaryIndexes=[
                {
                    "IndexName": "author-index",
                    "KeySchema": [
                        {"AttributeName": "Author", "KeyType": "HASH"},
                        {"AttributeName": "Publisher", "KeyType": "RANGE"},
                    ],
                    "Projection": {
                        "ProjectionType": "ALL",
                    },
                }
            ],
            # There are required on table creation
            ProvisionedThroughput={
                "ReadCapacityUnits": 10,
                "WriteCapacityUnits": 10,
            },
        )

        await table.wait_until_exists()
`}</code></pre>
    <p>{`Once you run the above command, if you have the AWS CLI installed locally, you can run the following command in your terminal to confirm that the table was indeed created:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`aws dynamodb list-tables --endpoint-url http://localhost:8000
`}</code></pre>
    <p>{`You should see our `}<inlineCode parentName="p">{`Books`}</inlineCode>{` table. Now you can run the `}<inlineCode parentName="p">{`put_item`}</inlineCode>{` to add a new item to the database.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      